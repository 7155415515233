/* eslint-disable vue/no-mutating-props */
<template>
  <div class="configBox">
    <div class="quick-check-mask" v-show="strategy === 1"></div>
    <upgrade-dialog
      v-model:show="isShowUpgrade"
      :showPoint="useIntegral"
      :showNormal="!useIntegral"
      type="A"
    ></upgrade-dialog>
    <div class="configBox-item">
      <div class="configBox-title">
        <img class="configBox-title__icon" src="@/assets/img/detection/environment-new.svg" />
        {{$c('basic.title')}}
      </div>
      <div class="quick-check">
        <img
          v-if="isUltraVires(configUserPromise.strategy)"
          src="@/assets/img/detection/vip.svg"
        />
        {{$c('basic.strategy.title')}}
      </div>
      <div class="configBox-content" @click="showUpgrade(!showConfig.default_quick_detect && taskType === 'Android')" :class="{ 'configBox-content__box--disable': forceQuickCheck}">
      <!-- <div class="configBox-content"> -->
        <el-radio-group v-model="strategy" @change="(e) => changeConfig(e,'strategy')">
          <el-radio :label="2">{{$c('basic.strategy.close')}}</el-radio>
          <el-radio :label="0">{{$c('basic.strategy.intelligent')}}</el-radio>
          <el-radio :label="1">{{$c('basic.strategy.enforce')}}</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div :class="{ 'uploadConfig-right__config--disable': true }">
      <div style="margin-top:0" class="configBox-item">
        <div class="configBox-content">
          <!-- 操作系统 -->
            <div
              class="configBox-content__box configBox-content__box--half configBox-content__box--left"
            >
              <div class="configBox-content__box--title">
                <img
                  v-if="isUltraVires(configUserPromise.system)"
                  src="@/assets/img/detection/vip.svg"
                />
                {{$c('basic.os.title')}}
              </div>
              <el-tooltip
                :disabled="showConfig.system"
                :content="$c('need_enterprise')"
                :show-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <div
                  @click="showUpgrade(!showConfig.system)"
                  :class="{ 'configBox-content__box--disable': !showConfig.system }"
                >
                  <el-select
                    v-model="machine.system"
                    @change="e => changeimageConfig(e, 'system')"
                    :placeholder="placeholderList.system"
                    teleported
                    :suffix-icon="arrow"
                    popper-class="detect-operate"
                  >
                    <template #prefix>
                      <el-tooltip
                        content="自定义镜像"
                        popper-class="configBox-tooltip"
                        transition="configBox-noTransition"
                        placement="top"
                        :effect="$isDark() ? 'dark' : 'light'"
                        v-if="machine.system.split(';').length > 3"
                      >
                        <img style="width: 16px; height: 16px;" :src="$img('detection/custom-images.png')" alt="">
                      </el-tooltip>
                    </template>
                    <el-option
                      v-for="item in getImageOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      @click="showUpgrade(item.disabled)"
                      :disabled="item.disabled"
                    >
                      <template #default>
                        <el-tooltip
                          content="自定义镜像"
                          popper-class="configBox-tooltip"
                          transition="configBox-noTransition"
                          placement="top"
                          :effect="$isDark() ? 'dark' : 'light'"
                          v-if="item.isCustom"
                        >
                          <img style="width: 16px; height: 16px;position: relative;top: 3px;" :src="$img('detection/custom-images.png')" alt="">
                        </el-tooltip>
                        {{ item.label }}
                      </template>
                    </el-option>
                  </el-select>
                </div>
              </el-tooltip>
            </div>
            <div class="configBox-content__box configBox-content__box--half">
              <div class="configBox-content__box--title">
                <!-- <img
                  v-if="isUltraVires(configUserPromise.lang)"
                  src="@/assets/img/detection/vip.svg"
                /> -->
                {{$c('basic.lang.title')}}
              </div>
              <el-tooltip
                :disabled="showConfig.lang"
                :content="$c('need_enterprise')"
                :visible-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <div
                  @click="showUpgrade(!showConfig.lang)"
                  :class="{ 'configBox-content__box--disable': !showConfig.lang }"
                >
                  <el-select
                    v-model="machine.lang"
                    @change="e => changeimageConfig(e, 'lang')"
                    :placeholder="placeholderList.lang"
                    teleported
                    :suffix-icon="arrow"
                    popper-class="detect-operate"
                  >
                    <el-option
                      v-for="item in getLangOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      @click="showUpgrade(item.disabled === true)"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-tooltip>
            </div>
            <div class="configBox-content__box">
              <div class="configBox-content__box--title">
                <img
                  v-if="isUltraVires(configUserPromise.software_major)"
                  src="@/assets/img/detection/vip.svg"
                />
                {{$c('basic.software_major.title')}}
              </div>
              <el-tooltip
                :disabled="showConfig.software_major"
                :content="$c('need_enterprise')"
                :visible-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <div
                  @click="showUpgrade(!showConfig.software_major)"
                  :class="{ 'configBox-content__box--disable': !showConfig.software_major }"
                >
                  <table-radio
                    :optionData="getSoftwareMajorOption"
                    v-model:value="machine.software_major"
                    @change="e => changeimageConfig(e, 'software_major')"
                    @disableClick="showUpgrade"
                    :placeholder="softwareMajorPlaceholder"
                  ></table-radio>
                </div>
              </el-tooltip>
            </div>
          <div class="configBox-content__box">
            <div class="configBox-content__box--title2">
              {{$c('basic.system_detail.title')}}
              <span @click="showVersionDetails = !showVersionDetails">{{
                showVersionDetails ? $c('basic.system_detail.hide') : $c('basic.system_detail.show')
              }}</span>
            </div>
            <system-detail
              v-if="showVersionDetails"
              :systemDeatil="getSystemDeatil"
            ></system-detail>
          </div>
        </div>
        <div class="configBox-footer"></div>
      </div>
      <div class="configBox-item" v-if="taskType !== 'Android' && taskType !== 'Linux'">
        <div class="configBox-title">
          <img class="configBox-title__icon" src="@/assets/img/detection/object-new.svg" />
          {{$c('dynamic.title')}}
        </div>
        <!-- 暂时不要下载文件了 -->
        <!-- <div v-if="type === 'url'" class="configBox-content">
        <div class="configBox-content__box configBox-content__box--half">
          <el-radio-group v-model="configData.urlObjectConfig" :disabled="!showConfig.urlObjectConfig" @change="changeConfig">
            <el-tooltip :disabled="showConfig.browser" content="需要升级为专业版" :visible-arrow="false" popper-class="configBox-tooltip" transition="configBox-noTransition" placement="bottom-end">
              <el-radio label="browser">在浏览器中打开
                <div class="configBox-content__box--radioChild"  :class="{'configBox-content__box--disable': !showConfig.browser || configData.urlObjectConfig !== 'browser'}">
                  <el-select v-model="configData.browser" placeholder="Internet Explorer" :teleported="false" @change="changeConfig">
                    <el-option
                      v-for="item in getBrowserOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </el-radio>
            </el-tooltip>
            <el-tooltip :disabled="showConfig.user_agent" content="需要升级为专业增强版" :visible-arrow="false" popper-class="configBox-tooltip" transition="configBox-noTransition" placement="bottom-end">
              <el-radio label="user_agent">下载文件
                <div class="configBox-content__box--radioChild"  :class="{'configBox-content__box--disable': !showConfig.user_agent || configData.urlObjectConfig !== 'user_agent'}">
                  <el-input
                    v-model="configData.user_agent"
                    :class="{'configBox-inputMsg': inputError.user_agent.value}"
                    @change="changeConfig(...arguments, 'user_agent')"
                    placeholder="输入自定义User Agent（可选）"></el-input>
                </div>
              </el-radio>
            </el-tooltip>
          </el-radio-group>
        </div>
      </div> -->
        <div v-if="(props.curFileType === 'URL' || (props.includeUrlAndFile && activeIndex === null))" class="configBox-content">
          <div class="configBox-content__box configBox-content__box--half">
            <div class="configBox-content__box--title">
              <img
                v-if="isUltraVires(configUserPromise.browser)"
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('dynamic.open_in_browser')}}
            </div>
            <el-tooltip
              :disabled="showConfig.browser"
              :content="$c('need_professional')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <div
                @click="showUpgrade(!showConfig.browser)"
                :class="{
                  'configBox-content__box--disable':
                    !showConfig.browser
                }"
              >
                <el-select
                  v-model="browser"
                  placeholder="Internet Explorer"
                  teleported
                  :suffix-icon="arrow"
                  popper-class="detect-operate"
                  @change="e => changeConfig(e, 'browser')"
                >
                  <el-option
                    v-for="item in getBrowserOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div v-if="(props.curFileType !== 'URL' || (props.includeUrlAndFile && activeIndex === null))" class="configBox-content">
          <!-- 操作系统 -->
          <div
            class="
              configBox-content__box configBox-content__box--half configBox-content__box--left
            "
          >
            <div class="configBox-content__box--title">
              <img
                v-if="isUltraVires(configUserPromise.custom_detect_type)"
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('dynamic.custom_detect_type.title')}}
            </div>
            <el-tooltip
              :disabled="showConfig.custom_detect_type"
              :content="$c('need_enterprise')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <div
                @click="showUpgrade(!showConfig.custom_detect_type)"
                :class="{ 'configBox-content__box--disable': !showConfig.custom_detect_type }"
              >
                <el-select
                  v-model="custom_detect_type"
                  @change="e => changeConfig(e, 'custom_detect_type')"
                  :placeholder="$c('dynamic.custom_detect_type.placeholder')"
                  teleported
                  popper-class="detect-operate"
                  :suffix-icon="arrow"
                >
                  <el-option
                    v-for="item in fileTypeOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-tooltip>
          </div>
          <div class="configBox-content__box" v-if="showCondition('cmd')">
            <div class="configBox-content__box--title">
              <img
                v-if="isUltraVires(configUserPromise.cmd)"
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('dynamic.cmd.title')}}
            </div>
            <el-tooltip
              :disabled="showConfig.cmd"
              :content="$c('need_enterprise')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <div
                @click="showUpgrade(!showConfig.cmd)"
                :class="{ 'configBox-content__box--disable': !showConfig.cmd }"
              >
                <el-input
                  v-model="cmd"
                  :class="{ 'configBox-inputMsg': inputError.cmd.value }"
                  @change="e => changeConfig(e, 'cmd')"
                  :placeholder="$c('dynamic.cmd.placeholder')"
                  maxlength="256"
                ></el-input>
              </div>
            </el-tooltip>
          </div>
          <div class="configBox-content__box"  v-if="showCondition('function')">
            <div class="configBox-content__box--title">
              <img
                v-if="isUltraVires(configUserPromise.function || configUserPromise.arguments)"
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('dynamic.function.title')}}
            </div>
            <el-tooltip
              :disabled="showConfig.function"
              :content="$c('need_enterprise')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <div
                @click="showUpgrade(!showConfig.function)"
                :class="{ 'configBox-content__box--disable': !showConfig.function }"
              >
                <el-input
                  class="configBox-content__box--input120"
                  v-model="_function"
                  :class="{ 'configBox-inputMsg': inputError.function.value }"
                  @change="e => changeConfig(e, 'function')"
                  :placeholder="$c('dynamic.function.placeholder')"
                  maxlength="100"
                ></el-input>
                <el-input
                  class="configBox-content__box--input356"
                  v-model="_arguments"
                  :class="{ 'configBox-inputMsg': inputError.arguments.value }"
                  @change="e => changeConfig(e, 'arguments')"
                  :placeholder="$c('dynamic.function.arguments')"
                  maxlength="100"
                ></el-input>
              </div>
            </el-tooltip>
          </div>
          <div class="configBox-content__box" v-if="showCondition('pwd_to_zip')">
            <div class="configBox-content__box--title">
              <img
                v-if="isUltraVires(configUserPromise.pwd_to_zip)"
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('dynamic.unzip_password.title')}}
            </div>
            <el-tooltip
              :disabled="showConfig.pwd_to_zip"
              :content="$c('need_enterprise')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <div
                @click="showUpgrade(!showConfig.pwd_to_zip)"
                :class="{ 'configBox-content__box--disable': !showConfig.pwd_to_zip }"
              >
                <el-input
                  v-model="pwd_to_zip"
                  :class="{ 'configBox-inputMsg': inputError.pwd_to_zip.value }"
                  @input="e => changeConfig(e, 'pwd_to_zip')"
                  :placeholder="$c('dynamic.unzip_password.placeholder')"
                  maxlength="100"
                ></el-input>
              </div>
            </el-tooltip>
          </div>
          <div class="configBox-content__box" v-show="isOpen[1] || openStatus('in_zip')" v-if="showCondition('in_zip')">
            <div class="configBox-content__box--title">
              <img
                v-if="isUltraVires(configUserPromise.in_zip)"
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('dynamic.specify_subfiles.title')}}
            </div>
            <el-tooltip
              :disabled="showConfig.in_zip"
              :content="$c('need_professional')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <div
                @click="showUpgrade(!showConfig.in_zip)"
                :class="{ 'configBox-content__box--disable': !showConfig.in_zip }"
              >
                <el-select
                  class="configBox-content__box--input120"
                  v-model="type_in_zip"
                  @change="e => changeConfig(e, 'type_in_zip')"
                  :placeholder="$c('dynamic.custom_detect_type.placeholder')"
                  teleported
                  popper-class="detect-operate"
                  :suffix-icon="arrow"
                >
                  <el-option key="md5" :label="$c('dynamic.specify_subfiles.md5.title')" value="md5_in_zip" />
                  <el-option key="md5" :label="$c('dynamic.specify_subfiles.file_name.title')" value="file_in_zip" />
                  <el-option key="md5" :label="$c('dynamic.specify_subfiles.file_type.title')" value="type_in_zip" />
                </el-select>
                <el-input
                  v-if="type_in_zip !== 'type_in_zip'"
                  class="configBox-content__box--input356 el-select__later"
                  v-model="in_zip"
                  :class="{ 'configBox-inputMsg': inputError.in_zip.value }"
                  @input="e => changeConfig(e, 'in_zip')"
                  :placeholder="type_in_zip === 'md5_in_zip' ? $c('dynamic.specify_subfiles.md5.placeholder') : $c('dynamic.specify_subfiles.file_name.placeholder')"
                  maxlength="50"
                ></el-input>
                <el-select
                  v-else
                  class="configBox-content__box--input356 el-select__later"
                  v-model="in_zip"
                  @change="e => changeConfig(e, 'in_zip')"
                  :placeholder="$c('dynamic.specify_subfiles.file_type.placeholder')"
                  teleported
                  popper-class="detect-operate"
                  :suffix-icon="arrow"
                >
                  <el-option key="" :label="$c('dynamic.specify_subfiles.file_type.placeholder')" value="" />
                  <el-option
                    v-for="item in fileType"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-tooltip>
          </div>
          <div class="configBox-content__box" v-show="isOpen[1] || openStatus('pwd_to_mso')" v-if="showCondition('pwd_to_mso')">
            <div class="configBox-content__box--title">
              <img
                v-if="isUltraVires(configUserPromise.pwd_to_mso)"
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('dynamic.pwd_to_mso.title')}}
            </div>
            <el-tooltip
              :disabled="showConfig.pwd_to_mso"
              :content="$c('need_professional')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <div
                @click="showUpgrade(!showConfig.pwd_to_mso)"
                :class="{ 'configBox-content__box--disable': !showConfig.pwd_to_mso }"
              >
                <el-input
                  v-model="pwd_to_mso"
                  :class="{ 'configBox-inputMsg': inputError.pwd_to_mso.value }"
                  @input="e => changeConfig(e, 'pwd_to_mso')"
                  :placeholder="$c('dynamic.pwd_to_mso.placeholder')"
                  maxlength="100"
                ></el-input>
              </div>
            </el-tooltip>
          </div>
          <div class="configBox-content__box" v-show="isOpen[1] || openStatus('office')" v-if="showCondition('office')">
            <div class="configBox-content__box--title">
              <img
                v-if="isUltraVires(configUserPromise.office)"
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('dynamic.app_forced')}}
            </div>
            <el-tooltip
              :disabled="showConfig.office"
              :content="$c('need_professional')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <div
                @click="showUpgrade(!showConfig.office)"
                :class="{ 'configBox-content__box--disable': !showConfig.office }"
              >
                <el-select
                  v-model="office"
                  @change="e => changeConfig(e, 'office')"
                  placeholder="Microsoft Office"
                  teleported
                  popper-class="detect-operate"
                  :suffix-icon="arrow"
                >
                  <el-option
                    key="mso"
                    label="Microsoft Office"
                    value="mso"
                  >
                  </el-option>
                  <el-option
                    v-if="showWps"
                    key="wps"
                    label="WPS"
                    value="wps"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-tooltip>
          </div>
          <div class="configBox-content__box" v-show="isOpen[1] || openStatus('emailer')" v-if="showCondition('emailer')">
            <div class="configBox-content__box--title">
              <img
                v-if="isUltraVires(configUserPromise.emailer)"
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('dynamic.emailer.title')}}
            </div>
            <el-tooltip
              :disabled="showConfig.emailer"
              :content="$c('need_professional')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <div
                @click="showUpgrade(!showConfig.emailer)"
                :class="{ 'configBox-content__box--disable': !showConfig.emailer }"
              >
                <el-select
                  v-model="emailer"
                  @change="e => changeConfig(e, 'emailer')"
                  :placeholder="$c('dynamic.emailer.placeholder')"
                  teleported
                  popper-class="detect-operate"
                  :suffix-icon="arrow"
                >
                  <el-option
                    key="extract"
                    :label="$c('dynamic.emailer.options.execute_attach')"
                    value="extract"
                  >
                  </el-option>
                  <el-option
                    key="outlook"
                    :label="$c('dynamic.emailer.options.outlook')"
                    value="outlook"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-tooltip>
          </div>
          <div class="configBox-content__box" v-show="isOpen[1]">
            <div class="configBox-content__box--title">
              <img
                v-if="
                  isUltraVires(configUserPromise.name_forced || configUserPromise.ext_forced)
                "
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('dynamic.name_forced.title')}}
            </div>
            <el-tooltip
              :disabled="showConfig.name_forced"
              :content="$c('need_enterprise')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <div
                @click="showUpgrade(!showConfig.name_forced)"
                :class="{ 'configBox-content__box--disable': !showConfig.name_forced }"
              >
                <el-input
                  class="configBox-content__box--input336"
                  v-model="name_forced"
                  :class="{ 'configBox-inputMsg': inputError.name_forced.value }"
                  @change="e => changeConfig(e, 'name_forced')"
                  :placeholder="$c('dynamic.name_forced.placeholder')"
                  maxlength="50"
                ></el-input>
                <el-input
                  class="configBox-content__box--input140"
                  v-model="ext_forced"
                  :class="{ 'configBox-inputMsg': inputError.ext_forced.value }"
                  @change="e => changeConfig(e, 'ext_forced')"
                  :placeholder="$c('dynamic.name_forced.ext_forced')"
                  maxlength="10"
                ></el-input>
              </div>
            </el-tooltip>
          </div>
          <div class="configBox-content__box" v-show="isOpen[1]">
            <div class="configBox-content__box--title">
              <img
                v-if="isUltraVires(configUserPromise.runpath)"
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('dynamic.runpath.title')}}
            </div>
            <el-tooltip
              :disabled="showConfig.runpath"
              :content="$c('need_enterprise')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <div
                @click="showUpgrade(!showConfig.runpath)"
                :class="{ 'configBox-content__box--disable': !showConfig.runpath }"
              >
                <el-select
                  v-model="runpath"
                  @change="e => changeConfig(e, 'runpath')"
                  :placeholder="$c('dynamic.runpath.placeholder')"
                  teleported
                  popper-class="detect-operate"
                  :suffix-icon="arrow"
                >
                  <el-option
                    v-for="item in wkdirOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="configBox-footer">
          <img
            v-if="props.curFileType !== 'URL' || (props.includeUrlAndFile && activeIndex === null)"
            class="configBox-footer__img"
            :class="{ 'configBox-footer__img--open': isOpen[1] }"
            src="@/assets/img/detection/open.svg"
            @click="changeOpen(1)"
          />
        </div>
      </div>
      <div class="configBox-item" v-if="taskType !== 'Linux'">
        <div class="configBox-title">
          <img class="configBox-title__icon" src="@/assets/img/detection/strategy-new.svg" />
          {{$c('strategy.title')}}
        </div>
        <div class="configBox-content">
          <div class="configBox-content__box">
            <div class="configBox-content__box--title">
              <img
                v-if="isUltraVires(configUserPromise.timeout)"
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('strategy.timeout.title')}}
            </div>
            <el-tooltip
              :disabled="configPermise.timeoutMax === 660"
              :content="`${
                configPermise.timeoutMax === 120 ? `${$c('strategy.timeout.professional')}\n` : ''
              }${$c('strategy.timeout.enterprise')}`"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <!-- <el-tooltip :disabled="showConfig.timeout" content="需要升级为专业增强版" :visible-arrow="false" popper-class="configBox-tooltip" transition="configBox-noTransition" placement="bottom-end"> -->
              <div @click="showUpgrade(!showConfig.timeout)">
                <el-slider
                  v-model="timeout"
                  @change="e => changeConfig(e, 'timeout')"
                  :disabled="!showConfig.timeout"
                  :show-tooltip="false"
                  :step="30"
                  :min="timeoutMin"
                  :max="660"
                  show-input
                >
                </el-slider>
              </div>
            </el-tooltip>
          </div>
          <div
            class="configBox-content__box configBox-content__box--width200"
            @click="showUpgrade(!showConfig.human)"
          >
            <div class="configBox-content__box--switchTitle" style="width: 185px;">
              <img
                v-if="isUltraVires(configUserPromise.human)"
                src="@/assets/img/detection/vip.svg"
              />
              {{ taskType !== 'Android' ? $c('strategy.human') : $c('strategy.android_human')}}
            </div>
            <el-tooltip
              :disabled="showConfig.human"
              :content="$c('need_enterprise')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <el-switch
                :disabled="!showConfig.human"
                v-model="human"
                @change="e => changeConfig(e, 'human')"
                active-color="#00ab7a"
                inactive-color="#7c7f8d"
              >
              </el-switch>
            </el-tooltip>
          </div>
          <div
            class="configBox-content__box configBox-content__box--width200"
            @click="showUpgrade(!showConfig.human_popbox)"
            v-if="taskType !== 'Android'"
          >
            <div class="configBox-content__box--switchTitle" style="width: 185px;">
              <img
                v-if="isUltraVires(configUserPromise.human_popbox)"
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('strategy.human_click')}}
            </div>
            <el-tooltip
              :disabled="showConfig.human_popbox"
              :content="$c('need_professional')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <el-switch
                :disabled="!showConfig.human_popbox || !human"
                v-model="human_popbox"
                @change="e => changeConfig(e, 'human_popbox')"
                active-color="#00ab7a"
                inactive-color="#7c7f8d"
              >
              </el-switch>
            </el-tooltip>
          </div>
          <div
            class="configBox-content__box configBox-content__box--width200"
            @click="showUpgrade(!showConfig.human_scroll)"
            v-if="taskType !== 'Android'"
          >
            <div class="configBox-content__box--switchTitle" style="width: 185px;">
              <img
                v-if="isUltraVires(configUserPromise.human_scroll)"
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('strategy.human_scroll')}}
            </div>
            <el-tooltip
              :disabled="showConfig.human_scroll"
              :content="$c('need_professional')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <el-switch
                :disabled="!showConfig.human_scroll || !human"
                v-model="human_scroll"
                @change="e => changeConfig(e, 'human_scroll')"
                active-color="#00ab7a"
                inactive-color="#7c7f8d"
              >
              </el-switch>
            </el-tooltip>
          </div>
          <!-- 这个里面的折叠就看不见了 -->
          <div v-show="isOpen[0]" class="configBox-content__fold">
            <div
              class="configBox-content__box configBox-content__box--width200"
              @click="showUpgrade(!showConfig.human_clipbd)"
              v-if="taskType !== 'Android'"
            >
              <div class="configBox-content__box--switchTitle" style="width: 185px;">
                <img
                  v-if="isUltraVires(configUserPromise.human_clipbd)"
                  src="@/assets/img/detection/vip.svg"
                />
                {{$c('strategy.human_clipbd')}}
              </div>
              <el-tooltip
                :disabled="showConfig.human_clipbd"
                :content="$c('need_professional')"
                :visible-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <el-switch
                  :disabled="!showConfig.human_clipbd || !human"
                  v-model="human_clipbd"
                  @change="e => changeConfig(e, 'human_clipbd')"
                  active-color="#00ab7a"
                  inactive-color="#7c7f8d"
                >
                </el-switch>
              </el-tooltip>
            </div>
            <div
              class="configBox-content__box configBox-content__box--width200"
              @click="showUpgrade(!showConfig.human_curpos)"
              v-if="taskType !== 'Android'"
            >
              <div class="configBox-content__box--switchTitle" style="width: 185px;">
                <img
                  v-if="isUltraVires(configUserPromise.human_curpos)"
                  src="@/assets/img/detection/vip.svg"
                />
                {{$c('strategy.human_curpos')}}
              </div>
              <el-tooltip
                :disabled="showConfig.human_curpos"
                :content="$c('need_professional')"
                :visible-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <el-switch
                  :disabled="!showConfig.human_curpos || !human"
                  v-model="human_curpos"
                  @change="e => changeConfig(e, 'human_curpos')"
                  active-color="#00ab7a"
                  inactive-color="#7c7f8d"
                >
                </el-switch>
              </el-tooltip>
            </div>
            <div
              class="configBox-content__box configBox-content__box--width200"
              @click="showUpgrade(!showConfig.human_autoin)"
              v-if="taskType !== 'Android'"
            >
              <div class="configBox-content__box--switchTitle" style="width: 185px;">
                <img
                  v-if="isUltraVires(configUserPromise.human_autoin)"
                  src="@/assets/img/detection/vip.svg"
                />
                {{$c('strategy.human_autoin')}}
              </div>
              <el-tooltip
                :disabled="showConfig.human_autoin"
                :content="$c('need_professional')"
                :visible-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <el-switch
                  :disabled="!showConfig.human_autoin || !human"
                  v-model="human_autoin"
                  @change="e => changeConfig(e, 'human_autoin')"
                  active-color="#00ab7a"
                  inactive-color="#7c7f8d"
                >
                </el-switch>
              </el-tooltip>
            </div>
            <div
              class="configBox-content__box configBox-content__box--width200"
              @click="showUpgrade(!showConfig.fast_detect)"
              v-if="taskType !== 'Android'"
            >
              <div class="configBox-content__box--switchTitle" style="width: 185px;">
                <img
                  v-if="isUltraVires(configUserPromise.fast_detect)"
                  src="@/assets/img/detection/vip.svg"
                />
                {{$c('strategy.fast_detect')}}
              </div>
              <el-tooltip
                :disabled="showConfig.fast_detect"
                :content="$c('need_professional')"
                :visible-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <el-switch
                  :disabled="!showConfig.fast_detect"
                  v-model="fast_detect"
                  @change="e => changeConfig(e, 'fast_detect')"
                  active-color="#00ab7a"
                  inactive-color="#7c7f8d"
                >
                </el-switch>
              </el-tooltip>
            </div>
            <div
              class="configBox-content__box configBox-content__box--width200"
              @click="showUpgrade(!showConfig.fake_reboot)"
              v-if="taskType !== 'Android'"
            >
              <div class="configBox-content__box--switchTitle" style="width: 185px;">
                <img
                  v-if="isUltraVires(configUserPromise.fake_reboot)"
                  src="@/assets/img/detection/vip.svg"
                />
                {{$c('strategy.fake_reboot')}}
              </div>
              <el-tooltip
                :disabled="showConfig.fake_reboot"
                :content="$c('need_professional')"
                :visible-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <el-switch
                  :disabled="!showConfig.fake_reboot"
                  v-model="fake_reboot"
                  @change="e => changeConfig(e, 'fake_reboot')"
                  active-color="#00ab7a"
                  inactive-color="#7c7f8d"
                >
                </el-switch>
              </el-tooltip>
            </div>
            <div
              class="configBox-content__box configBox-content__box--width200"
              @click="showUpgrade(!showConfig.active_reboot)"
              v-if="taskType !== 'Android'"
            >
              <div class="configBox-content__box--switchTitle" style="width: 185px;">
                <img
                  v-if="isUltraVires(configUserPromise.active_reboot)"
                  src="@/assets/img/detection/vip.svg"
                />
                {{$c('strategy.active_reboot')}}
              </div>
              <el-tooltip
                :disabled="showConfig.active_reboot"
                :content="$c('need_enterprise')"
                :visible-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <el-switch
                  :disabled="!showConfig.active_reboot"
                  v-model="active_reboot"
                  @change="e => activeRebootChange(e, 'active_reboot')"
                  active-color="#00ab7a"
                  inactive-color="#7c7f8d"
                >
                </el-switch>
              </el-tooltip>
            </div>
            <div
              class="configBox-content__box configBox-content__box--width200"
              @click="showUpgrade(!showConfig.pwd_forced)"
              v-if="taskType !== 'Android'"
            >
              <div class="configBox-content__box--switchTitle" style="width: 185px;">
                {{$c('strategy.pwd_forced')}}
              </div>
              <el-tooltip
                :disabled="showConfig.pwd_forced"
                :content="$c('need_professional')"
                :visible-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <el-switch
                  :disabled="!showConfig.pwd_forced"
                  v-model="pwd_forced"
                  @change="e => changeConfig(e, 'pwd_forced')"
                  active-color="#00ab7a"
                  inactive-color="#7c7f8d"
                >
                </el-switch>
              </el-tooltip>
            </div>
            <div
              class="configBox-content__box configBox-content__box--width200"
              @click="showUpgrade(!showConfig.hook_sleep)"
              v-if="taskType !== 'Android'"
            >
              <div class="configBox-content__box--switchTitle" style="width: 185px;">
                <img
                  v-if="isUltraVires(configUserPromise.hook_sleep)"
                  src="@/assets/img/detection/vip.svg"
                />
                {{$c('strategy.hook_sleep')}}
              </div>
              <el-tooltip
                :disabled="showConfig.hook_sleep"
                :content="$c('need_enterprise')"
                :visible-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <el-switch
                  :disabled="!showConfig.hook_sleep"
                  v-model="hook_sleep"
                  @change="e => changeConfig(e, 'hook_sleep')"
                  active-color="#00ab7a"
                  inactive-color="#7c7f8d"
                >
                </el-switch>
              </el-tooltip>
            </div>
            <div
              class="configBox-content__box configBox-content__box--width200"
              @click="showUpgrade(!showConfig.fake_av)"
              v-if="taskType !== 'Android'"
            >
              <div class="configBox-content__box--switchTitle" style="width: 185px;">
                <img
                  v-if="isUltraVires(configUserPromise.fake_av)"
                  src="@/assets/img/detection/vip.svg"
                />
                {{$c('strategy.fake_av')}}
              </div>
              <el-tooltip
                :disabled="showConfig.fake_av"
                :content="$c('need_enterprise')"
                :visible-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <el-switch
                  :disabled="!showConfig.fake_av"
                  v-model="fake_av"
                  @change="e => changeConfig(e, 'fake_av')"
                  active-color="#00ab7a"
                  inactive-color="#7c7f8d"
                >
                </el-switch>
              </el-tooltip>
            </div>
            <div
              class="
                configBox-content__box configBox-content__box--half configBox-content__box--left
              "
              v-if="taskType !== 'Android'"
            >
              <div class="configBox-content__box--title">
                <img
                  v-if="isUltraVires(configUserPromise.timezone)"
                  src="@/assets/img/detection/vip.svg"
                />
                {{$c('strategy.timezone.title')}}
              </div>
              <el-tooltip
                :disabled="showConfig.timezone"
                :content="$c('need_enterprise')"
                :visible-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <div
                  @click="showUpgrade(!showConfig.timezone)"
                  :class="{ 'configBox-content__box--disable': !showConfig.timezone }"
                >
                  <el-select
                    v-model="timezone"
                    @change="e => changeConfig(e, 'timezone')"
                    :placeholder="$c('strategy.timezone.placeholder')"
                    teleported
                    popper-class="detect-operate"
                    :suffix-icon="arrow"
                  >
                    <el-option
                      v-for="item in timezoneOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-tooltip>
            </div>
            <div class="configBox-content__box configBox-content__box--half" v-if="taskType !== 'Android'">
              <div class="configBox-content__box--title">
                <img
                  v-if="isUltraVires(configUserPromise.geo)"
                  src="@/assets/img/detection/vip.svg"
                />
                {{$c('strategy.geo.title')}}
              </div>
              <el-tooltip
                :disabled="showConfig.geo"
                :content="$c('need_enterprise')"
                :visible-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <div
                  @click="showUpgrade(!showConfig.geo)"
                  :class="{ 'configBox-content__box--disable': !showConfig.geo }"
                >
                  <el-select
                    v-model="geo"
                    @change="e => changeConfig(e, 'geo')"
                    :placeholder="$c('strategy.geo.placeholder')"
                    teleported
                    popper-class="detect-operate"
                    :suffix-icon="arrow"
                  >
                    <el-option
                      v-for="item in regionOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-tooltip>
            </div>
            <div class="configBox-content__box dateEditor">
              <div class="configBox-content__box--title">
                <img
                  v-if="isUltraVires(configUserPromise.datetime)"
                  src="@/assets/img/detection/vip.svg"
                />
                {{$c('strategy.datetime.title')}}
              </div>
              <el-tooltip
                :disabled="showConfig.datetime"
                :content="$c('need_enterprise')"
                :visible-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <div
                  @click="showUpgrade(!showConfig.datetime)"
                  :class="{ 'configBox-content__box--disable': !showConfig.datetime }"
                >
                  <el-date-picker
                    v-model="datetime"
                    popper-class="detect-operate"
                    placement="bottom-start"
                    @change="e => changeConfig(e, 'datetime')"
                    type="datetime"
                    :clearable="false"
                    :editable="false"
                    teleported
                    :placeholder="$c('strategy.datetime.placeholder')"
                  >
                  </el-date-picker>
                </div>
              </el-tooltip>
            </div>
            <div class="configBox-content__box" v-if="taskType !== 'Android'">
              <div class="configBox-content__box--title">
                <img
                  v-if="isUltraVires(configUserPromise.env_var)"
                  src="@/assets/img/detection/vip.svg"
                />
                {{$c('strategy.env_var.title')}}
              </div>
              <el-tooltip
                :disabled="showConfig.env_var"
                :content="$c('need_enterprise')"
                :visible-arrow="false"
                popper-class="configBox-tooltip"
                transition="configBox-noTransition"
                placement="bottom-end"
                :effect="$isDark() ? 'dark' : 'light'"
              >
                <div
                  @click="showUpgrade(!showConfig.env_var)"
                  :class="{ 'configBox-content__box--disable': !showConfig.env_var }"
                >
                  <json-list
                    v-model:value="env_var"
                    @inputError="jsonListError"
                    @change="e => changeConfig(e, 'env_var')"
                  ></json-list>
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
        <template v-if="!(taskType === 'Linux' || taskType === 'Android')">
          <div class="configBox-footer">
            <img
              class="configBox-footer__img"
              :class="{ 'configBox-footer__img--open': isOpen[0] }"
              src="@/assets/img/detection/open.svg"
              @click="changeOpen(0)"
            />
          </div>
        </template>
      </div>
      <div class="configBox-item">
        <div class="configBox-title">
          <img class="configBox-title__icon" src="@/assets/img/detection/network-new.svg" />
          {{$c('internet.title')}}
        </div>
        <div class="configBox-content">
          <div
            class="configBox-content__box"
            @click="showUpgrade(!showConfig.internet)"
          >
            <div class="configBox-content__box--switchTitle" style="width: 185px;">
              <img
                v-if="isUltraVires(configUserPromise.internet)"
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('internet.connect')}}
            </div>
            <el-tooltip
              :disabled="showConfig.internet"
              :content="$c('need_enterprise')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <el-switch
                :disabled="!showConfig.internet"
                v-model="internet"
                @change="e => internetChange(e, 'internet')"
                active-color="#00ab7a"
                inactive-color="#7c7f8d"
              >
              </el-switch>
            </el-tooltip>
          </div>
          <!-- <div class="configBox-content__box">
          <div class="configBox-content__box--switchTitle">开启 Fake Net</div>
          <el-tooltip :disabled="showConfig.fake_net" content="需要升级为专业增强版" :visible-arrow="false" popper-class="configBox-tooltip" transition="configBox-noTransition" placement="bottom-end">
            <el-switch
              :disabled="!showConfig.fake_net || configData.internet"
              @change="fakeNetChange"
              v-model="configData.fake_net"
              active-color="#537EF3"
              inactive-color="#353F6D">
            </el-switch>
          </el-tooltip>
        </div> -->
          <div
            v-if="taskType !== 'Linux'"
            class="configBox-content__box"
            @click="showUpgrade(!showConfig.decrypt_https)"
          >
            <div class="configBox-content__box--switchTitle" style="width: 185px;">
              <img
                v-if="isUltraVires(configUserPromise.decrypt_https)"
                src="@/assets/img/detection/vip.svg"
              />
              {{$c('internet.decrypt_https')}}
            </div>
            <el-tooltip
              :disabled="showConfig.decrypt_https"
              :content="$c('need_professional')"
              :visible-arrow="false"
              popper-class="configBox-tooltip"
              transition="configBox-noTransition"
              placement="bottom-end"
              :effect="$isDark() ? 'dark' : 'light'"
            >
              <el-switch
                :disabled="https_disabled"
                @change="e => changeConfig(e, 'decrypt_https')"
                v-model="decrypt_https"
                active-color="#00ab7a"
                inactive-color="#7c7f8d"
              >
              </el-switch>
            </el-tooltip>
          </div>
          <!-- <div class="configBox-content__box">
          <div class="configBox-content__box--title">
            <img v-if="isUltraVires(configUserPromise.exitLocation)" src="@/assets/images/detection/vip.svg">
            设置互联网出口位置</div>
          <el-tooltip :disabled="showConfig.exitLocation" content="需要升级为专业增强版" :visible-arrow="false" popper-class="configBox-tooltip" transition="configBox-noTransition" placement="bottom-end">
            <div :class="{'configBox-content__box--disable': !showConfig.exitLocation || !configData.internet}">
              <el-select v-model="configData.exitLocation" @change="changeConfig" placeholder="选择区域，如美国/洛杉矶" :teleported="false">
                <el-option
                  v-for="item in exitLocationOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-tooltip>
        </div>
        <div v-show="isOpen[2]" class="configBox-content__fold">
          <div class="configBox-content__box">
            <div class="configBox-content__box--checkboxTitle">
              <el-tooltip :disabled="showConfig.userRequiredCheckbox" content="需要升级为专业增强版" :visible-arrow="false" popper-class="configBox-tooltip" transition="configBox-noTransition" placement="bottom-end">
                <el-checkbox :disabled="!showConfig.useNetCheckbox || !configData.internet" v-model="configData.useNetCheckbox" @change="changeConfig">网络流量路由方式</el-checkbox>
              </el-tooltip>
              <span></span>
            </div>
            <div class="configBox-content__box--next">
              <el-radio-group v-model="configData.netRadio" :disabled="!showConfig.netRadio || !configData.useNetCheckbox || !configData.internet" @change="changeConfig">
                <el-tooltip :disabled="showConfig.tor" content="需要升级为专业增强版" :visible-arrow="false" popper-class="configBox-tooltip" transition="configBox-noTransition" placement="bottom-end">
                  <el-radio label="tor">TOR 路由
                    <div class="configBox-content__box--radioChild" :class="{'configBox-content__box--disable': !showConfig.tor || configData.netRadio !== 'tor' || !configData.useNetCheckbox || !configData.internet}">
                      <el-select v-model="configData.tor"  @change="changeConfig" placeholder="最快的地理位置" :teleported="false">
                        <el-option
                          v-for="item in torOption"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                  </el-radio>
                </el-tooltip>
                <el-tooltip :disabled="showConfig.vpn" content="需要升级为专业增强版" :visible-arrow="false" popper-class="configBox-tooltip" transition="configBox-noTransition" placement="bottom-end">
                  <el-radio label="vpn">VPN
                    <div class="configBox-content__box--radioChild" :class="{'configBox-content__box--disable': !showConfig.vpn || configData.netRadio !== 'vpn' || !configData.useNetCheckbox || !configData.internet}">
                      <el-select v-model="configData.vpn" @change="changeConfig" placeholder="选择 VPN 配置" :teleported="false">
                        <el-option
                          v-for="item in vpnOption"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                  </el-radio>
                </el-tooltip>
                <el-radio label="proxy">网络代理
                  <div class="configBox-content__box--radioChild">
                    <el-tooltip :disabled="showConfig.proxy" content="需要升级为专业增强版" :visible-arrow="false" popper-class="configBox-tooltip" transition="configBox-noTransition" placement="bottom-end">
                      <div :class="{'configBox-content__box--disable': !showConfig.proxy || configData.netRadio !== 'proxy' || !configData.useNetCheckbox || !configData.internet}">
                        <el-select class="configBox-content__box--select82" v-model="configData.proxy" @change="changeConfig" placeholder="HTTP" :teleported="false">
                          <el-option
                            v-for="item in proxyOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                        <el-input class="configBox-content__box--input162" @change="changeConfig" placeholder="地址"></el-input>
                        <el-input class="configBox-content__box--input184" @change="changeConfig" placeholder="端口"></el-input>
                      </div>
                    </el-tooltip>
                    <div class="configBox-content__box">
                      <el-tooltip :disabled="showConfig.userRequiredCheckbox" content="需要升级为专业增强版" :visible-arrow="false" popper-class="configBox-tooltip" transition="configBox-noTransition" placement="bottom-end">
                        <el-checkbox :disabled="!showConfig.userRequiredCheckbox || configData.netRadio !== 'proxy' || !configData.useNetCheckbox || !configData.internet" v-model="configData.userRequiredCheckbox" @change="changeConfig">代理服务器要求密码</el-checkbox>
                      </el-tooltip>
                      <el-tooltip :disabled="showConfig.username" content="需要升级为专业增强版" :visible-arrow="false" popper-class="configBox-tooltip" transition="configBox-noTransition" placement="bottom-end">
                        <div class="configBox-content__box--item" :class="{'configBox-content__box--disable': !showConfig.username || configData.netRadio !== 'proxy' || !configData.useNetCheckbox || !configData.userRequiredCheckbox || !configData.internet}">
                          <span class="configBox-content__box--key">用户名</span>
                          <el-input class="configBox-content__box--value" @change="changeConfig" placeholder="输入用户名"></el-input>
                        </div>
                      </el-tooltip>
                      <el-tooltip :disabled="showConfig.password" content="需要升级为专业增强版" :visible-arrow="false" popper-class="configBox-tooltip" transition="configBox-noTransition" placement="bottom-end">
                        <div class="configBox-content__box--item" :class="{'configBox-content__box--disable': !showConfig.password || configData.netRadio !== 'proxy' || !configData.useNetCheckbox || !configData.userRequiredCheckbox || !configData.internet}">
                          <span class="configBox-content__box--key">密码</span>
                          <el-input class="configBox-content__box--value" @change="changeConfig" placeholder="输入密码"></el-input>
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                </el-radio>
              </el-radio-group>
            </div>
          </div>
        </div> -->
        </div>
        <div class="configBox-footer">
          <!-- <img
          class="configBox-footer__img"
          :class="{'configBox-footer__img--open': isOpen[2]}"
          src="@/assets/images/detection/open.svg"
          @click="changeOpen(2)"> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import tableRadio from './tableRadio'
import jsonList from './jsonList'
import systemDetail from './systemDetail'
import arrow from 'components/common/arrow'
import { useState } from '@/hooks/useVuex'
import { useStore } from 'vuex'
import UpgradeDialog from '@/components/common/upgradeDialog'
import { defineProps, defineEmits, ref, toRefs, reactive, watch, nextTick, onMounted, computed, watchEffect } from 'vue'
import {
  getImages,
  getImagesTimezone,
  getImagesRegion
  // getImagesWkdir
} from 'services/detection'
import { configPermise, permiseLevel, fileType } from './permise'
import { t, isZh } from 'app/i18n'

const store = useStore()
const { userInfo, scoreInfo } = useState('user', ['userInfo', 'scoreInfo'])
const gid = store.getters.gid

const props = defineProps({
  configData: {
    type: Object,
    default: () => ({
      // timeout: 120
    })
  },
  // 用户用了选项的权限都会记录在里面
  configUserPromise: {
    type: Object,
    default: () => {}
  },
  // 是否使用积分系统，现在单个任务,而且得登录才能使用，不使用的话提示框没有充积分的
  useIntegral: {
    type: Boolean,
    default: true
  },
  type: {
    type: String,
    default: 'file'
  },
  activeIndex: {
    default: null
  },
  taskType: {
    type: String,
    default: ''
  },
  curFileType: {
    type: String,
    default: ''
  },
  dataList: {
    type: Array,
    default: () => {
      return []
    }
  },
  includeUrlAndFile: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:configData', 'delete:configData', 'inputError', 'change'])

const { configData, configUserPromise, useIntegral, activeIndex, taskType } = toRefs(props)

const strategy = ref(configData.value.strategy || 2)
const cmd = ref(configData.value.cmd || '')

const pwd_to_zip = ref(configData.value.pwd_to_zip || '')
const type_in_zip = ref(configData.value.type_in_zip || 'md5_in_zip')
const in_zip = ref(configData.value.in_zip || '')
const pwd_to_mso = ref(configData.value.pwd_to_mso || '')
const office = ref(configData.value.office || 'mso')
const emailer = ref(configData.value.emailer || 'extract')

const browser = ref(configData.value.browser || '')
const custom_detect_type = ref(configData.value.custom_detect_type)
const _function = ref(configData.value.function || '')
const _arguments = ref(configData.value.arguments || '')
const name_forced = ref(configData.value.name_forced || '')
const ext_forced = ref(configData.value.ext_forced || '')
const runpath = ref(configData.value.runpath || '')
const timeout = ref(configData.value.timeout || 120)
const human = ref(configData.value.human || true)
const human_popbox = ref(configData.value.human_popbox || true)
const human_scroll = ref(configData.value.human_scroll || true)
const human_curpos = ref(configData.value.human_curpos || true)
const human_autoin = ref(configData.value.human_autoin || true)
const fake_reboot = ref(configData.value.fake_reboot || true)
const fast_detect = ref(configData.value.fast_detect || true)
const human_clipbd = ref(configData.value.human_clipbd || true)
const pwd_forced = ref(configData.value.pwd_forced || true)
const fake_av = ref(configData.value.fake_av || false)
const hook_sleep = ref(configData.value.hook_sleep || true)
const active_reboot = ref(configData.value.active_reboot || false)
const env_var = ref(configData.value.env_var || [])
const internet = ref(configData.value.internet || true)
const decrypt_https = ref(props.configData.decrypt_https || false)
// setTimeout(() => {
//   console.log(props.configData)
// }, 1000)
const timezone = ref(configData.value.timezone || '')
const datetime = ref(configData.value.datatime || '')
const geo = ref(configData.value.ge || '')

function updateProps(value, label) {
  emit('update:configData', label, value)
}

const $c = computed(params => {
  return (params) => {
    return t(`config.${params}`)
  }
})

watchEffect(() => {
  // const arr = ['strategy', 'cmd', 'custom_detect_type', 'name_forced', 'ext_forced', 'runpath', 'timeout', 'active_reboot', 'human', 'timezone', 'geo', 'datetime', 'env_var', 'decrypt_https', 'internet']
  strategy.value = configData.value.strategy
  cmd.value = configData.value.cmd
  pwd_to_zip.value = configData.value.pwd_to_zip
  type_in_zip.value = configData.value.type_in_zip
  in_zip.value = configData.value.in_zip
  pwd_to_mso.value = configData.value.pwd_to_mso
  office.value = configData.value.office
  emailer.value = configData.value.emailer
  custom_detect_type.value = configData.value.custom_detect_type
  _function.value = configData.value.function
  _arguments.value = configData.value.arguments
  name_forced.value = configData.value.name_forced
  ext_forced.value = configData.value.ext_forced
  runpath.value = configData.value.runpath
  // timeout.value = configData.value.timeout
  human.value = configData.value.human
  human_popbox.value = configData.value.human_popbox
  human_scroll.value = configData.value.human_scroll
  human_curpos.value = configData.value.human_curpos
  human_autoin.value = configData.value.human_autoin
  fake_reboot.value = configData.value.fake_reboot
  fast_detect.value = configData.value.fast_detect
  human_clipbd.value = configData.value.human_clipbd
  pwd_forced.value = configData.value.pwd_forced
  fake_av.value = configData.value.fake_av
  hook_sleep.value = configData.value.hook_sleep
  active_reboot.value = configData.value.active_reboot
  timezone.value = configData.value.timezone
  geo.value = configData.value.geo
  datetime.value = configData.value.datetime
  env_var.value = configData.value.env_var
  decrypt_https.value = configData.value.decrypt_https
  internet.value = configData.value.internet
  console.log(internet.value)
})

// const isShowUpgrade = ref(false)
// const showVersionDetails = ref(false)
// const isOpen = ref([])
// const images = ref([])
// const softwareMajorPlaceholder = ref('请选择')

// const emit = defineEmits(['inputError', 'change'])

const state = reactive({
  isShowUpgrade: false,
  showVersionDetails: false,
  isOpen: [],
  showConfig: {},
  images: [],
  softwareMajorPlaceholder: t('config.basic.software_major.placeholder'),
  machine: {
    system: '',
    lang: '',
    software_major: ''
  },
  fileTypeOption: [],
  timezoneOption: [],
  regionOption: [],
  wkdirOption: [],
  torOption: [],
  vpnOption: [],
  proxyOption: [],
  exitLocationOption: [],
  inputError: {
    user_agent: {
      value: false,
      regular: /[^\x00-\xff]/g // eslint-disable-line
    },
    cmd: {
      value: false,
      regular: /[^\x00-\xff]/g // eslint-disable-line
    },
    function: {
      value: false,
      regular: /[^\x00-\xff]/g // eslint-disable-line
    },
    arguments: {
      value: false,
      regular: /[^\x00-\xff]/g // eslint-disable-line
    },
    name_forced: {
      value: false,
      regular: /(\\)|(\/)|(:)|(\*)|(\?)|(\")|(\<)|(\>)|(\|)|[^\x00-\xff]/g // eslint-disable-line
    },
    ext_forced: {
      value: false,
      regular: /(\\)|(\/)|(:)|(\*)|(\?)|(\")|(\<)|(\>)|(\|)|[^\x00-\xff]/g // eslint-disable-line
    },
    in_zip: {
      value: false,
      regular: /(\\)|(\/)|(:)|(\*)|(\?)|(\")|(\<)|(\>)|(\|)|[^\x00-\xff]/g // eslint-disable-line
    },
    pwd_to_zip: {
      value: false,
      regular: /(\s)|[^\x00-\xff]/g // eslint-disable-line
    },
    pwd_to_mso: {
      value: false,
      regular: /(\s)|[^\x00-\xff]/g // eslint-disable-line
    }
  },
  _configPermise: {
    timeoutMax: 60
  },
  timeoutMin: 60
})

// 对应的配置项需要怎么样的权限
const optionLevel = {
  strategy: 2, // 免费用户兑换了权限后提交android文件 关闭快速检测
  timezone: 3,
  geo: 3,
  datetime: 3,
  env_var: 3,
  function: 3,
  arguments: 2,
  browser: 2,
  active_reboot: 3,
  human_autoin: 2,
  pwd_forced: 2,
  fake_av: 3,
  decrypt_https: 2,
  office: 2,
  in_zip: 2,
  pwd_to_zip: 2,
  pwd_to_mso: 2,
  exitLocation: 3,
  vpn: 3,
  proxy: 3,
  userRequiredCheckbox: 3,
  username: 3,
  password: 3
}

const { isShowUpgrade, showVersionDetails, isOpen, showConfig, images, softwareMajorPlaceholder, machine, fileTypeOption, timezoneOption, regionOption, wkdirOption, inputError, _configPermise, timeoutMin } = toRefs(state)

// 当前用户拥有的权限，可能比之前的权限高
const getUserPermise = computed(() => {
  // 只有使用积分系统才会提高权限
  if (useIntegral.value) {
    if (scoreInfo.value?.detection_enter_last > 0 && userInfo.value.gid < 4) {
      return 'enterprise'
    }
    if (scoreInfo.value?.detection_pro_last > 0 && userInfo.value.gid < 3) {
      return 'professional'
    }
  }
  return userInfo.value.group
})

const https_disabled = computed(() => (!showConfig.value.decrypt_https || !internet.value))

const is64 = computed(() => props.curFileType.includes('64'))

const forceQuickCheck = computed(() => {
  return !showConfig.value.default_quick_detect && (taskType.value === 'Android' || is64.value)
})

// 把当前的权益转换成数值
const getUserLevel = computed(() => {
  if (userInfo.value.group === 'professional') {
    return 2
  }
  if (userInfo.value.group === 'enterprise' || userInfo.value.group === 'qihoo') {
    return 3
  }
  return 1
})

const placeholderList = computed(() => {
  return {
    system: getImageOption.value[0] && getImageOption.value[0].label,
    lang: (getLangOption.value[0] && getLangOption.value[0].label) || t('config.basic.lang.zh_label')
  }
})

const getImageOption = computed(() => {
  const group = getUserPermise.value || 'visitor'
  let data2 = []
  images.value.forEach((i) => {
    if (data2.findIndex((t) => t.value === `${i.os};${i.major};${i.arch}`) === -1) {
      const label = `${i.os} ${i.major} ${i.arch}${t('config.basic.os.suffix')}`
      const value = `${i.os};${i.major};${i.arch}`
      data2.push({
        label,
        value,
        disabled: !configPermise[group].system.option.includes(value)
      })
    } else if (i.os_alias) { // 自定义镜像
      const value = `${i.os};${i.major};${i.arch};${i.custom_name}`
      const label = i.os_alias.length < 55 ? `${i.os_alias}` : i.os_alias.slice(0, 52) + '...'
      data2.push({
        label,
        value,
        isCustom: `${i.os};${i.major};${i.arch}`,
        disabled: false
      })
    }
  })
  // 提交64位文件时屏蔽32位操作系统
  if (is64.value) {
    data2 = data2.filter(v => v.value.includes('64'))
  }

  data2.sort((a, b) => {
    const splita = a.value.split(';')
    const splitb = b.value.split(';')
    const verA = parseInt(splita[1])
    const digitA = parseInt(splita[2])
    const verB = parseInt(splitb[1])
    const digitB = parseInt(splitb[2])
    return verA !== verB ? verA - verB : digitA - digitB
  })
  return data2
})

const getLangOption = computed(() => {
  const [os, major, arch] = machine.value.custom ? machine.value.custom.split(';') : machine.value.system.split(';')
  const langList = [
    ...new Set(
      images.value
        .filter((i) => i.os === os && i.major === major && i.arch === arch)
        .map((i) => i.lang)
    )
  ]
  const keyMap = {
    'zh-cn': {
      label: t('config.basic.lang.zh_label'),
      disabled: false
    },
    'en-us': {
      label: t('config.basic.lang.en_label'),
      // disabled: !getUserPermise.value || getUserPermise.value === 'free'
      disabled: false
    }
  }
  if (images.value.length === 1) {
    return [
      {
        label: keyMap[images.value[0].lang].label,
        value: images.value[0].lang,
        disabled: false
      }
    ]
  }
  return langList.map((i) => ({
    label: keyMap[i].label,
    value: i,
    disabled: keyMap[i].disabled
  }))
})

const showWps = computed(() => {
  if (machine.value.software_major) {
    const currentMachine = getSoftwareMajorOption.value.find(item => item.value === machine.value.software_major)
    if (currentMachine && currentMachine.label.indexOf('WPS') > -1) {
      return true
    }
  }
  return false
})

const getSoftwareMajorOption = computed(() => {
  let os, major, arch, lang, image_name
  if (taskType.value === 'Linux') {
    [os, major, arch, lang] = ['Ubuntu', '18.04', '64', 'en-us']
  } else if (taskType.value === 'Android') {
    [os, major, arch, lang] = ['Android', '11', '64', 'en-us']
  } else {
    [os, major, arch, lang] = ['Windows', '7 SP1 Pro', '32', 'zh-cn']
  }
  const group = getUserPermise.value || 'visitor'
  const levels = permiseLevel[group]
  if (machine.value.system) {
    [os, major, arch, image_name] = machine.value.system.split(';')
  }
  if (machine.value.lang) {
    lang = machine.value.lang
  }
  // if (machine.value.image_name) {
  //   image_name = machine.value.image_name
  // }
  const data = images.value
    .filter(
      (item) => {
        return image_name ? (item.custom_name === image_name) : (item.os === os && item.major === major && item.arch === arch && item.lang === lang && !item.custom_name)
      }
    )
    .map((item) => ({
      value: item.image_name,
      label: item.software.map((item) => `${item.alias} ${item.major}`).join('; '),
      software: item.software.map((item) => ({
        alias: item.alias,
        major: item.major
      })),
      disabled: !levels.includes(item.level),
      level: item.level
    }))
  return data.sort((a, b) => a.level - b.level)
})

const getSystemDeatil = computed(() => {
  let imageName = machine.value.software_major
  if (imageName === '') {
    if (!getSoftwareMajorOption.value[0]) {
      return {}
    }
    imageName = getSoftwareMajorOption.value[0].value
  }
  return images.value.find((item) => item.image_name === imageName)
})

const getBrowserOption = computed(() => {
  const browser = [
    {
      label: 'Internet Explorer',
      value: 'ie'
    },
    {
      label: 'Mozilla Firefox',
      value: 'firefox'
    },
    {
      label: 'Google Chrome',
      value: 'chrome'
    }
  ]
  if (!getSystemDeatil.value.software) {
    return browser
  }
  const hasBrowser = getSystemDeatil.value.software.map((i) => i.alias.toLowerCase())
  if (browser.value && !hasBrowser.includes(browser.value)) {
    updateProps('', 'browser')
  }
  return browser.filter((item) => hasBrowser.includes(item.value))
})

watch([activeIndex, taskType], (v) => {
  // if (v[0] === null) return
  getImagesOption()
})
watch([userInfo, getUserPermise, taskType], () => setShowConfig())
watch(timeout, (data) => {
  console.log(data)
  if (data > _configPermise.value.timeoutMax) {
    nextTick(() => {
      timeout.value = _configPermise.value.timeoutMax
      changeConfig(_configPermise.value.timeoutMax, 'timeout')
      showUpgrade()
    })
  }
}, { immediate: false })

onMounted(() => {
  getImagesOption()
  getImagesFiletypeOption()
  getImagesTimezoneOption()
  getImagesRegionOption()
  getImagesWkdirOption()
  setShowConfig()
  // getConstants()
  // 设置timeout的默认值
  // nextTick(() => {
  //   configData.timeout = 120
  // })
})

function isUltraVires(level) {
  return getUserLevel.value < (level || 0)
}

function openStatus(key) {
  if (key === 'in_zip') {
    if (!(showCondition('cmd') && showCondition('function') && showCondition('pwd_to_zip'))) {
      return true
    }
  }
  if (key === 'pwd_to_mso') {
    if (!showCondition('cmd') && !showCondition('function')) {
      return true
    }
    if (!showCondition('cmd') && !showCondition('pwd_to_zip')) {
      return true
    }
    if (!showCondition('cmd') && !showCondition('in_zip')) {
      return true
    }
    if (!showCondition('function') && !showCondition('pwd_to_zip')) {
      return true
    }
    if (!showCondition('function') && !showCondition('in_zip')) {
      return true
    }
    if (!showCondition('pwd_to_zip') && !showCondition('in_zip')) {
      return true
    }
  }
  if (key === 'office') {
    if (!showCondition('cmd') && !showCondition('function') && !showCondition('pwd_to_zip')) {
      return true
    }
    if (!showCondition('cmd') && !showCondition('function') && !showCondition('in_zip')) {
      return true
    }
    if (!showCondition('cmd') && !showCondition('function') && !showCondition('pwd_to_mso')) {
      return true
    }
    if (!showCondition('cmd') && !showCondition('pwd_to_zip') && !showCondition('in_zip')) {
      return true
    }
    if (!showCondition('cmd') && !showCondition('pwd_to_zip') && !showCondition('pwd_to_mso')) {
      return true
    }
    if (!showCondition('cmd') && !showCondition('in_zip') && !showCondition('pwd_to_mso')) {
      return true
    }
    if (!showCondition('function') && !showCondition('pwd_to_zip') && !showCondition('in_zip')) {
      return true
    }
    if (!showCondition('function') && !showCondition('pwd_to_zip') && !showCondition('pwd_to_mso')) {
      return true
    }
    if (!showCondition('function') && !showCondition('in_zip') && !showCondition('pwd_to_mso')) {
      return true
    }
    if (!showCondition('pwd_to_zip') && !showCondition('in_zip') && !showCondition('pwd_to_mso')) {
      return true
    }
  }
  if (key === 'emailer') {
    if (!showCondition('cmd') && !showCondition('function') && !showCondition('pwd_to_zip') && !showCondition('in_zip')) {
      return true
    }
    if (!showCondition('cmd') && !showCondition('function') && !showCondition('pwd_to_zip') && !showCondition('pwd_to_mso')) {
      return true
    }
    if (!showCondition('cmd') && !showCondition('function') && !showCondition('pwd_to_zip') && !showCondition('office')) {
      return true
    }
    if (!showCondition('cmd') && !showCondition('function') && !showCondition('in_zip') && !showCondition('pwd_to_mso')) {
      return true
    }
    if (!showCondition('cmd') && !showCondition('function') && !showCondition('in_zip') && !showCondition('office')) {
      return true
    }
    if (!showCondition('cmd') && !showCondition('pwd_to_zip') && !showCondition('in_zip') && !showCondition('pwd_to_mso')) {
      return true
    }
    if (!showCondition('cmd') && !showCondition('pwd_to_zip') && !showCondition('in_zip') && !showCondition('office')) {
      return true
    }
    if (!showCondition('cmd') && !showCondition('pwd_to_zip') && !showCondition('pwd_to_mso') && !showCondition('office')) {
      return true
    }
    if (!showCondition('cmd') && !showCondition('in_zip') && !showCondition('pwd_to_mso') && !showCondition('office')) {
      return true
    }

    if (!showCondition('function') && !showCondition('pwd_to_zip') && !showCondition('in_zip') && !showCondition('pwd_to_mso')) {
      return true
    }
    if (!showCondition('function') && !showCondition('pwd_to_zip') && !showCondition('in_zip') && !showCondition('office')) {
      return true
    }
    if (!showCondition('function') && !showCondition('pwd_to_zip') && !showCondition('pwd_to_mso') && !showCondition('office')) {
      return true
    }
    if (!showCondition('function') && !showCondition('in_zip') && !showCondition('pwd_to_mso') && !showCondition('office')) {
      return true
    }
    if (!showCondition('pwd_to_zip') && !showCondition('in_zip') && !showCondition('pwd_to_mso') && !showCondition('office')) {
      return true
    }
  }
  return false
}

function showCondition(label) {
  if (custom_detect_type.value) {
    // 命令行
    if (label === 'cmd') {
      if (custom_detect_type.value === 'exe') {
        return true
      }
    }
    // 动态库参数
    if (label === 'function') {
      if (custom_detect_type.value === 'dll') {
        return true
      }
    }
    // 解压密码
    if (label === 'pwd_to_zip') {
      if (custom_detect_type.value === 'zip' || custom_detect_type.value === 'rar' || custom_detect_type.value === '7z') {
        return true
      }
    }
    // 指定压缩文件中的子文件
    if (label === 'in_zip') {
      if (custom_detect_type.value === 'zip' || custom_detect_type.value === 'rar' || custom_detect_type.value === '7z') {
        return true
      }
    }
    // 文档密码
    if (label === 'pwd_to_mso') {
      if (custom_detect_type.value === 'pdf' || custom_detect_type.value === 'doc' || custom_detect_type.value === 'xls' || custom_detect_type.value === 'ppt' || custom_detect_type.value === 'rtf') {
        return true
      }
    }
    // 指定文档打开方式
    if (label === 'office') {
      if (custom_detect_type.value === 'doc' || custom_detect_type.value === 'xls' || custom_detect_type.value === 'ppt' || custom_detect_type.value === 'rtf') {
        return true
      }
    }
    // 邮件打开方式
    if (label === 'emailer') {
      if (custom_detect_type.value === 'eml' || custom_detect_type.value === 'msg') {
        return true
      }
    }
  } else {
    // 命令行
    if (label === 'cmd') {
      if (props.activeIndex !== null && ['exe32', 'exe64'].indexOf(props.curFileType) > -1) {
        return true
      }
      if (props.activeIndex === null && hasSameItem(['exe32', 'exe64'], props.dataList.map(item => item.fileType))) {
        return true
      }
    }
    // 动态库参数
    if (label === 'function') {
      if (props.activeIndex !== null && ['dll32', 'dll64'].indexOf(props.curFileType) > -1) {
        return true
      }
      if (props.activeIndex === null && hasSameItem(['dll32', 'dll64'], props.dataList.map(item => item.fileType))) {
        return true
      }
    }
    // 解压密码
    if (label === 'pwd_to_zip') {
      if (props.activeIndex !== null && ['zip', 'rar', '7z', 'xz', 'xar', 'tar', 'nsis', 'gz', 'cab', 'bzip2'].indexOf(props.curFileType) > -1) {
        return true
      }
      if (props.activeIndex === null && hasSameItem(['zip', 'rar', '7z', 'xz', 'xar', 'tar', 'nsis', 'gz', 'cab', 'bzip2'], props.dataList.map(item => item.fileType))) {
        return true
      }
    }
    // 指定压缩文件中的子文件
    if (label === 'in_zip') {
      if (props.activeIndex !== null && ['zip', 'rar', '7z', 'xz', 'xar', 'tar', 'nsis', 'gz', 'cab', 'bzip2'].indexOf(props.curFileType) > -1) {
        return true
      }
      if (props.activeIndex === null && hasSameItem(['zip', 'rar', '7z', 'xz', 'xar', 'tar', 'nsis', 'gz', 'cab', 'bzip2'], props.dataList.map(item => item.fileType))) {
        return true
      }
    }
    // 文档密码
    if (label === 'pwd_to_mso') {
      if (props.activeIndex !== null && ['doc', 'docx', 'docm', 'xls', 'xlsx', 'xlsm', 'ppt', 'pptx', 'pptm', 'rtf', 'ole', 'olex', 'olet', 'odt', 'pdf'].indexOf(props.curFileType) > -1) {
        return true
      }
      if (props.activeIndex === null && hasSameItem(['doc', 'docx', 'docm', 'xls', 'xlsx', 'xlsm', 'ppt', 'pptx', 'pptm', 'rtf', 'ole', 'olex', 'olet', 'odt', 'pdf'], props.dataList.map(item => item.fileType))) {
        return true
      }
    }
    // 指定文档打开方式
    if (label === 'office') {
      if (props.activeIndex !== null && ['doc', 'docx', 'docm', 'xls', 'xlsx', 'xlsm', 'ppt', 'pptx', 'pptm', 'rtf', 'ole', 'olex', 'olet', 'odt'].indexOf(props.curFileType) > -1) {
        return true
      }
      if (props.activeIndex === null && hasSameItem(['doc', 'docx', 'docm', 'xls', 'xlsx', 'xlsm', 'ppt', 'pptx', 'pptm', 'rtf', 'ole', 'olex', 'olet', 'odt'], props.dataList.map(item => item.fileType))) {
        return true
      }
    }
    // 邮件打开方式
    if (label === 'emailer') {
      if (props.activeIndex !== null && ['eml', 'msg'].indexOf(props.curFileType) > -1) {
        return true
      }
      if (props.activeIndex === null && hasSameItem(['eml', 'msg'], props.dataList.map(item => item.fileType))) {
        return true
      }
    }
  }
  return false
}

function hasSameItem(arr1, arr2) {
  return arr1.some(item => arr2.includes(item))
}

function setShowConfig() {
  const group = getUserPermise.value || 'visitor'
  for (const key in configPermise[group]) {
    showConfig.value[key] = !configPermise[group][key].disable
  }
  setConfigPermise(group)
}
// 设置一些特殊配置项的权限
function setConfigPermise(group) {
  _configPermise.value.timeoutMax = configPermise[group].timeout.max
  if ((taskType.value === 'Android' || is64.value) && gid <= 1) {
    strategy.value = configPermise[group].default_quick_detect.strategy
    changeConfig(configPermise[group].default_quick_detect.strategy, 'strategy') // 初始化strategy的值，设置是否使用权限图标
  }
}
function setMachine() {
  isOpen.value = []
  // 不需要有默认值,默认值用placeholder显示
  if (configData.value.machine) {
    const image = images.value.find((item) => configData.value.machine === item.image_name)
    if (image) {
      machine.value = {
        system: `${image.os};${image.major};${image.arch}${image.custom_name ? `;${image.custom_name}` : ''}`,
        lang: image.lang,
        software_major: image.image_name,
        image_name: image.image_name
      }
    } else {
      // 自定义镜像提交的任务 删除镜像后重新检测 恢复为默认镜像
      updateProps(getSoftwareMajorOption.value[0].value, 'machine')
    }
  } else {
    machine.value = {
      system: '',
      lang: '',
      software_major: ''
    }
    const images = getImageOption
    setTimeout(() => {
      if (images.value && images.value.length) {
        machine.value.system = images.value[0].value
      }
      const majors = getSoftwareMajorOption.value
      if (majors && majors.length) {
        if (is64.value && !forceQuickCheck.value) { // 专业版提交64位文件手动选择一下64位machine，免费版则强制快速检测
          updateProps(getSoftwareMajorOption.value[0].value, 'machine')
          updateProps(false, 'useBase')
        }
        // machine.software_major = majors[0].value
        softwareMajorPlaceholder.value = getSoftwareMajorOption.value[0].label || t('config.basic.software_major.non_select')
      } else {
        softwareMajorPlaceholder.value = t('config.basic.software_major.non_select')
      }
    }, 500)

    // 都没有选的时候不要给默认值
    // configData.machine = getSoftwareMajorOption[0].value
    // machine.software_major = getSoftwareMajorOption[0].value
  }
}
function changeOpen(index) {
  isOpen.value[index] = !isOpen.value[index]
}
function jsonListError(error) {
  emit('inputError', error)
}
function changeConfig(data, label, intercept = false) {
  !intercept && updateProps(data, label)
  // 输入框错误检测
  if (inputError.value[label]) {
    const item = inputError.value[label]
    if (label === 'in_zip' && data && type_in_zip.value === 'md5_in_zip') {
      // 验证md5
      item.value = !/^[a-fA-F0-9]{32}$/.test(data)
    } else {
      item.value = data.match(item.regular)
    }
    // 如果存在一个为true的就暴露出错误
    let error = false
    for (const key in inputError.value) {
      if (inputError.value[key].value) {
        error = true
        break
      }
    }
    emit('inputError', error)
  }
  // 处理用户的点击是不是高级操作
  configUserPromise.value[label] = optionLevel[label] || 1
  // 如果置空或者关闭或者设置成自动就认为没有选择这个选项
  // 设置成0是因为在dll那需要判断两个，得有一个是0
  if (!data) {
    configUserPromise.value[label] = 0
  }
  // 特殊处理语言
  if (label === 'lang') {
    if (data === 'en-us') {
      configUserPromise.value[label] = 2
    } else {
      configUserPromise.value[label] = 1
    }
  }
  if (label === 'strategy') {
    if ((data === 0 || data === 2) && taskType.value === 'Android') {
      configUserPromise.value[label] = 2
    } else {
      configUserPromise.value[label] = 1
    }
  }
  // 特殊处理操作系统
  if (label === 'system') {
    if (data === 'Windows;7 SP1 Pro;64') {
      configUserPromise.value[label] = 2
    } else if (
      data === 'Windows;8.1 Update 1 Ent;64' ||
      data === 'Windows;8.1 Update 1 Ent;32' ||
      data === 'Windows;10 Ent LTSB 2016;64' ||
      data === 'Windows;10 Ent LTSB 2016;32'
    ) {
      configUserPromise.value[label] = 3
    } else {
      configUserPromise.value[label] = 1
    }
    // 重新选择了操作系统后环境得变成第一个，就直接听操作系统的就好
    configUserPromise.value.software_major = configUserPromise.value[label]
  }
  // 特殊处理环境
  if (label === 'software_major') {
    configUserPromise.value[label] = data
  }
  // 特殊处理环境变量
  if (label === 'env_var') {
    // 如果存在一个不是空，就是使用了
    if (data.some((item) => item.key !== '' || item.value !== '')) {
      configUserPromise.value[label] = 3
    } else {
      configUserPromise.value[label] = 1
    }
  }
  // 特殊数量分析时长
  if (label === 'timeout') {
    if (data > 360) {
      configUserPromise.value[label] = 3
    } else if (data > 120) {
      configUserPromise.value[label] = 2
    } else {
      configUserPromise.value[label] = 1
    }
  }
  if (label === 'human' && !data) {
    updateProps(false, 'human_scroll')
    human_scroll.value = false
    updateProps(false, 'human_curpos')
    human_curpos.value = false
    updateProps(false, 'human_autoin')
    human_autoin.value = false
    updateProps(false, 'human_clipbd')
    human_clipbd.value = false
    updateProps(false, 'human_popbox')
    human_popbox.value = false
  }
  if (label === 'active_reboot' && data) {
    updateProps(false, 'fake_reboot')
    fake_reboot.value = false
  }
  if (label === 'fake_reboot' && data) {
    updateProps(false, 'active_reboot')
    active_reboot.value = false
  }
  if (label === 'type_in_zip' && data) {
    updateProps('', 'in_zip')
    in_zip.value = ''
  }
  if (label === 'software_major' && data) {
    updateProps('', 'office')
    office.value = ''
    updateProps('', 'browser')
    browser.value = ''
  }
  emit('change')
}
function internetChange(data, label) {
  changeConfig(data, label)
  if (data) {
    emit('delete:configData', 'fake_net')
  } else {
    const arr = ['exitLocation', 'tor', 'vpn', 'proxy', 'username', 'password', 'fake_net', 'decrypt_https']
    arr.map(v => emit('delete:configData', v))
    updateProps(false, 'useNetCheckbox')
    updateProps('tor', 'netRadio')
    updateProps(false, 'userRequiredCheckbox')
  }
}
// function fakeNetChange(data) {
//   if (data) {
//     delete configData.internet
//   }
// }
function activeRebootChange(data, label) {
  changeConfig(data, label, true)
  if (timeout.value < 120) {
    active_reboot.value = false
    store.commit('app/showMessage', {
      type: 'error',
      message: t('config.min_timeout'),
      duration: 1000,
      showClose: true
    })
  } else {
    updateProps(data, label)
  }
  // if (configData.value.active_reboot) {
  //   timeoutMin.value = 120
  // } else {
  //   timeoutMin.value = 60
  // }
}
function changeimageConfig(data, type) {
  changeConfig(data, type)
  if (type === 'software_major') {
    // 如果改变应用环境
    // configData.machine = machine.value.software_major
    updateProps(machine.value.software_major, 'machine')
  } else {
    // 如果改变其他的就重置应用环境
    machine.value.software_major = ''
    machine.value.image_name = ''
    machine.value.custom = ''
    const image = images.value.find(v => v.custom_name === data)
    if (image) {
      machine.value.image_name = image.image_name
      machine.value.custom = `${image.os};${image.major};${image.arch}`
    }
    // 使用下拉选项第一个
    if (getSoftwareMajorOption.value[0]) {
      softwareMajorPlaceholder.value = getSoftwareMajorOption.value[0].label || t('config.basic.software_major.non_select')
      updateProps(getSoftwareMajorOption.value[0].value, 'machine')
    } else {
      softwareMajorPlaceholder.value = t('config.basic.software_major.non_select')
      updateProps('', 'machine')
    }
    // machine.software_major = getSoftwareMajorOption[0].value
  }
}
function getImagesOption() {
  const _type = taskType.value === 'Linux' ? { os: 'Ubuntu' } : taskType.value === 'Android' ? { os: 'Android' } : { os: 'Windows' }
  getImages(_type)
    .then((res) => {
      images.value = res
      setMachine()
    })
    .catch((err) => {
      console.log(err)
      store.commit('app/showMessage', {
        type: 'error',
        message: t('detection.submit_failed'),
        duration: 1000
      })
    })
}
function getImagesFiletypeOption() {
  fileTypeOption.value = fileType.map((item) => ({
    label: item,
    value: item
  }))
  fileTypeOption.value.unshift({
    label: t('config.dynamic.custom_detect_type.placeholder'),
    value: ''
  })
}
function getImagesWkdirOption() {
  // getImagesWkdir()
  // .then(res => {
  //   wkdirOption = res.map(item =>({
  //     label: item,
  //     value: item
  //   }))
  // })
  // .catch(() => {
  //   $store.commit('SHOWMESSAGE', { type: 'error', message: '提交失败，网络延时，请重新提交', duration: 1000 })
  // })
  wkdirOption.value = [
    {
      label: t('config.dynamic.runpath.options.auto'),
      value: ''
    },
    {
      label: t('config.dynamic.runpath.options.desktop'),
      value: 'desktop'
    },
    {
      label: t('config.dynamic.runpath.options.document'),
      value: 'document'
    },
    {
      label: t('config.dynamic.runpath.options.user'),
      value: 'user'
    },
    {
      label: t('config.dynamic.runpath.options.download'),
      value: 'download'
    },
    {
      label: t('config.dynamic.runpath.options.appdata'),
      value: 'appdata'
    },
    {
      label: t('config.dynamic.runpath.options.temp'),
      value: 'temp'
    },
    {
      label: t('config.dynamic.runpath.options.windows'),
      value: 'windows'
    },
    {
      label: t('config.dynamic.runpath.options.rootdir'),
      value: 'rootdir'
    }
  ]
}
function getImagesRegionOption() {
  getImagesRegion({
    lang: isZh() ? 'zh' : 'en'
  })
    .then((res) => {
      console.log(res)
      regionOption.value = res.map((item) => ({
        label: item[1],
        value: item[0]
      }))
      regionOption.value.unshift({
        label: t('config.strategy.geo.placeholder'),
        value: ''
      })
    })
    .catch((err) => {
      console.log(err)
      store.commit('app/showMessage', {
        type: 'error',
        message: t('detection.submit_failed'),
        duration: 1000
      })
    })
}
function getImagesTimezoneOption() {
  getImagesTimezone({
    lang: isZh() ? 'zh' : 'en'
  })
    .then((res) => {
      timezoneOption.value = res.map((item) => ({
        label: item[1],
        value: item[0]
      }))
      timezoneOption.value.unshift({
        label: t('config.strategy.timezone.placeholder'),
        value: ''
      })
    })
    .catch((err) => {
      console.log(err)
      store.commit('app/showMessage', {
        type: 'error',
        message: t('detection.submit_failed'),
        duration: 1000
      })
    })
}
function showUpgrade(type) {
  if (type === false) return
  isShowUpgrade.value = true
}

</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
[data-theme='light'] {
  .configBox-footer {
    border-color: #E8EAED !important;
  }
}

.configBox  {
  position: relative;
  .quick-check-mask  {
    position: absolute;
    top: 98px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--detect-config-mask);
    transition: background 0.5s;
    z-index: 1000;
  }
  .quick-check-info  {
    position: relative;
    top: 1px;
  }
  &-inputMsg  {
    position: relative;
    &:after  {
      content: '\8bf7\8f93\5165\6b63\786e\7684\5b57\7b26\4e32';
      position: absolute;
      left: 5px;
      top: 35px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color:#F06D67;
    }
  }
  &-item  {
    margin-top: 16px;
    &:last-child  {
      & .configBox-footer  {
        border-bottom: none;
      }
    }
  }
  &-title  {
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-text-1);
    &__icon  {
      height: 16px;
      margin:0 4px -3px 0;
    }
  }
  .quick-check  {
    color: var(--color-text-1);
    margin: 16px 0 6px;
  }
  &-footer  {
    border-bottom: 1px dashed;

    border-color: var(--detect-dividing-line);
    padding-bottom: 16px;
    text-align: center;
    &__img  {
      width: 20px;
      margin-top: 16px;
      transform: rotate(180deg);
      transition: transform 0.5s;
      filter: var(--svg-filter3);
      cursor: pointer;
      &--open  {
        transform: rotate(0deg);
      }
      &:hover  {
        filter: var(--svg-filter4);
      }
    }
  }
  &-content  {
    & :deep(.el-radio-group){
      .el-radio__label {
        color: var(--color-text-2);
      }
    }
    &__box  {
      margin-top: 20px;
      :deep(.el-input__inner) {
        background-color: transparent !important;
      }
      &--width200  {
        // width: 200px;
      }
      &--flex  {
        width: 207px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &--half  {
        width: calc(50% - 8px);
        display: inline-block;
      }
      &--left  {
        margin-right: 16px;
      }
      &--title  {
        height: 20px;
        line-height: 20px;
        margin-bottom: 6px;
        color: var(--color-text-1);
        & img  {
          transform: translateY(1px);
        }
      }
      &--title2  {
        height: 20px;
        line-height: 20px;
        margin-bottom: 6px;
        padding-left: 12px;
        position: relative;
        color: var(--color-text-1);
        &:before  {
          content: ' ';
          height: 6px;
          width: 6px;
          border-radius: 3px;
          background-color: var(--color-primary);
          position: absolute;
          left: 0;
          top: 7px;
        }
        & span  {
          margin-left: 5px;
          cursor: pointer;
          color: var(--color-primary);
        }
      }
      &--switchTitle  {
        height: 20px;
        margin-right: 40px;
        line-height: 20px;
        display: inline-block;
      }
      &--checkboxTitle  {
        margin-bottom: 20px;
        height: 20px;
        & span  {
          display: inline-block;
          width: 334px;
          float: right;
          border-bottom: 1px dashed #4D588B;
          margin-top: 9px;
        }
      }
      &--next  {
        padding-left: 20px;
      }
      &--radioChild  {
        width: calc(100% - 20px);
        margin: 6px 0 20px 20px;
        &:last-child  {
          margin-bottom: 0px;
        }
      }
      &--input120  {
        width: calc(25% - 4px);
        margin-right: 8px;
        &.el-select {
          width: calc(35% - 4px) !important;
        }
      }
      &--input356  {
        width: calc(75% - 4px);
        &.el-select__later {
          width: calc(65% - 4px) !important;
        }
      }
      &--input336  {
        width: calc(70% - 4px);
        margin-right: 8px;
      }
      &--input140  {
        width: calc(30% - 4px);
      }
      &--select82  {
        width: calc(20% - 5px) !important;
        margin-right: 8px;
      }
      &--input162  {
        width: calc(38% - 5px);
        margin-right: 8px;
      }
      &--input184  {
        width: calc(42% - 6px);
      }
      &--item  {
        margin-top: 20px;
      }
      &--key  {
        display: inline-block;
        width: 50px;
        font-size: 14px;
      }
      &--value  {
        width: calc(100% - 50px);
      }
      &--disable  {
        position: relative;
        cursor: pointer !important;
        &::before  {
          content: ' ';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background-color: var(--detect-config-mask);
          z-index: 1001;
        }
      }
      & :deep(.el-select){
        @include select();
        .el-select-dropdown__item.is-disabled  {

          cursor: pointer !important;
        }
      }
       &  :deep(.el-date-editor){
        width: 100%;
        position: relative;
        &:after {
          content: url('../../../assets/img/detection/date.svg');
          position: absolute;
          right: 10px;
          top: 2px;
          pointer-events: none;
        }
        & .el-input__prefix, & .el-popper__arrow  {
          display: none;
        }
      }
      &  :deep(.dateTimePicker){
        @include dateEditor();
      }
      & :deep(.el-input){
        @include input();
      }
      & :deep(.el-switch){
        @include switch();
        &.is-disabled .el-switch__core, &.is-disabled .el-switch__label  {
          cursor: pointer !important;
        }
      }
      & :deep(.el-slider){
        @include slider();
      }
      & :deep(.el-checkbox){
        @include checkbox();
      }
      & :deep(.el-radio-group){
        @include radioGroup();
        & .el-radio  {
          display: inline-block;
          width: 100%;
          margin-top: 20px;
          &:first-child  {
            margin-top: 0;
          }
        }
      }
    }
  }
  .slider-wrapper {
    display: flex;
    & > span {
      line-height: 32px;
      padding-left: 8px;
    }
  }
}
</style>
