<template>
  <div class="systemDetail">
    <div class="systemDetail-item systemDetail-system">
      <el-tooltip
        content="自定义镜像"
        popper-class="configBox-tooltip"
        transition="configBox-noTransition"
        placement="top"
        :effect="$isDark() ? 'dark' : 'light'"
        v-if="props.systemDeatil.custom_name"
      >
        <img style="width: 16px; height: 16px;position: relative;top: 3px;" :src="$img('detection/custom-images.png')" alt="">
      </el-tooltip>
      <span class="systemDetail-item__left">{{getSystemInfo}}</span>
      <span class="systemDetail-item__right">{{getSystemBuild}}</span>
    </div>
    <div class="systemDetail-item systemDetail-software" v-for="(item, index) in systemDeatil.software" :key="index">
      <span class="systemDetail-item__left">{{getSoftwareInfo(item)}}</span>
      <span class="systemDetail-item__right">{{getSoftwareBuild(item)}}</span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { t } from 'app/i18n'

const props = defineProps({
  systemDeatil: {
    type: Object
  }
})

const getSystemInfo = computed(() => `
    ${props.systemDeatil.os}
    ${props.systemDeatil.major}
    ${props.systemDeatil.arch}${t('config.basic.lang.suffix')}
    ${t(`config.basic.lang.map.${props.systemDeatil.lang}`) || t('config.basic.lang.suffix')}
  `)

const getSystemBuild = computed(() => `${props.systemDeatil.build}`)

function getSoftwareInfo (item) {
  return `
        ${item.name}
        ${item.major}
        ${item.arch}${t('config.basic.lang.suffix')}
        ${t(`config.basic.lang.map.${props.systemDeatil.lang}`) || t('config.basic.lang.suffix')}
      `
}
function getSoftwareBuild (item) {
  return `
        ${item.build}
      `
}

</script>
<style lang="scss" scoped>
.systemDetail  {
  color: var(--color-text-2);
  padding: 0px 12px 10px 12px;
  border-color: var(--color-text-3);
  background: var(--detect-config-os);
  box-shadow: var(--shadow);
  border-radius: 2px;
  position: absolute;
  left: 0;
  right: -10px;
  z-index: 1200;
  &-item  {
    height: 20px;
    line-height: 20px;
    margin-top: 5px;
    &__right  {
      float: right;
    }
  }
  &-system  {
    height: 36px;
    line-height: 36px;
    color: var(--color-text-1) !important;
    font-size: 12px;
    border-bottom: 1px dashed;
    margin-bottom: 10px;
    color: var( --color-unkown-text);
    border-color: var(--color-text-3);
  }
  &-software  {
    margin-top: 4px;
    font-size: 12px;
    color: var(--color-text-2);
  }
}
</style>
